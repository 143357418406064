import { motion } from "framer-motion";
import React from "react";
import { pageTransitionVariants } from "./../settings";
import logo from "../assets/image/logo/logo5.png";
import cango from "../assets/image/hero/cango.jpg";
import { siteContent } from "./../content/siteContent";
// import aboutus from './../assets/image/hero/aboutus.png'

export class AboutUs extends React.Component {
    constructor(props) {
        super(props);
        this.state = { windowWidth: window.innerWidth };
      }
    
      handleResize = (e) => {
        this.setState({ windowWidth: window.innerWidth });
      };
    
      componentDidMount() {
        window.addEventListener("resize", this.handleResize);
      }
    
      componentWillUnmount() {
        window.addEventListener("resize", this.handleResize);
      }

  render() {
    const { windowWidth } = this.state;
    const content = siteContent[`${this.props.language}`].aboutus;
    const triangleClipStyle =
      windowWidth > 640
        ? { clipPath: `polygon(25% 0%, 10% 100%, 100% 100%, 100% 0%)` }
        : {};
    return (
      <motion.div
        className="flex flex-col w-full h-full justify-start items-center"
        initial="initial"
        animate="in"
        exit="out"
        variants={pageTransitionVariants}
        transition={pageTransitionVariants.transition}
      >
        <div className="w-full flex flex-col sm:flex-row h-full sm:h-96 bg-third-500">
          <div
            className="flex flex-col sm:w-5/12 h-96 sm:h-full justify-center items-center"
            style={{ minWidth: "300px" }}
          >
            <img src={logo} className="w-2/3 mx-auto" alt="" />
            <p className="text-2xl mt-4 text-center font-bold text-secondary-500 mx-auto w-2/3">
              {content.slogan}
            </p>
          </div>
          <div
            className="w-full sm:w-7/12 h-96 sm:h-full flex justify-center items-center bg-primary-900 shadow-lg"
            style={triangleClipStyle}
          >
            <img src={cango} className="w-full  sm:w-3/4 my-auto mx-auto" alt="" />
          </div>
        </div>

        <h1 className="mt-10 block text-6xl text-secondary-500 tracking-tight font-extrabold">
          {content.title}
        </h1>

        <div className="flex flex-col items-center space-y-3 text-center mb-20">
          <p className="mt-4 w-full sm:w-1/2 px-4  text-center mx-auto text-xl tracking-tight font-light">
            {content.text}
          </p>
          <p className="text-lg font-mono font-bold">{content.quota1}</p>
          <p className="text-lg font-mono font-bold">{content.quota2}</p>
          <p className="text-lg font-mono font-bold">{content.quota3}</p>
        </div>
      </motion.div>
    );
  }
}
