import React from "react";
import { HashLink } from "react-router-hash-link";
import hero from "./../assets/image/hero/heroimage3.png";
import figure1 from "./../assets/svg/shape-1.svg";


export class Hero extends React.Component { 
  render() {
    const {content,language} = this.props
    return (
      <div className="flex flex-row flex-wrap p-3 md:p-10">
        <div className="w-full md:w-1/2">
          <div className="relative z-10 pb-8 bg-white sm:pb-16 md:pb-20 lg:max-w-2xl lg:w-full lg:pb-28 xl:pb-32">
            <main className="mt-10 mx-auto max-w-7xl px-4 sm:mt-12 sm:px-6 md:mt-16 lg:mt-20 lg:px-8 xl:mt-28">
              <div className="sm:text-center lg:text-left">
                <h1 className="text-4xl tracking-tight font-extrabold text-secondary-900 sm:text-5xl md:text-6xl">
                  <span className="block">{content.first}</span>
                  <span className="block text-primary-500 xl:inline">
                   {content.second}
                  </span>
                </h1>
                <p className="mt-3 text-base text-gray-500 sm:mt-5 sm:text-lg sm:max-w-xl sm:mx-auto md:mt-5 md:text-xl lg:mx-0">
                  {content.third}
                </p>
                <div className="mt-5 sm:mt-8 sm:flex sm:justify-center lg:justify-start">
                  <div className="rounded-md shadow">
                    <HashLink
                      smooth
                      to={`/home?language=${language}#contactform`}
                      className="w-full flex items-center justify-center px-8 py-3 text-base font-medium rounded-md text-white bg-third-500 hover:bg-third-600 md:py-4 md:text-lg md:px-10"
                    >
                      {content.fourth}
                    </HashLink>
                  </div>
                </div>
              </div>
            </main>
          </div>
        </div>
        <div
          className="w-full h-3/4 md:w-1/2 flex justify-center md:justify-start items-start"
          style={{ backgroundImage: `url(${figure1})` }}
        >
          <img className="w-1/2 md:w-3/4 lg:w-7/12" src={hero} alt="" />
        </div>
      </div>
    );
  }
}
