import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react';
import BackgroundImage from './../assets/svg/Liquid-Cheese2.svg'
import WebFormImage from './../assets/svg/webform.png'
import { faCalendarAlt, faTruck, faAngleRight } from '@fortawesome/free-solid-svg-icons'



function Feature(props) {
 
    let boxStyle = "backdrop-blur shadow-2xl flex flex-col items-center mx-auto border-t-8 border-gray-900 w-80 sm:w-80 md:w-80 lg:w-60 xl:w-60 2xl:w-80 mb-5 space-x-1 space-y-2  p-5 filter-gray";
    let sectionStyle = { backgroundImage: `url(${BackgroundImage})`, backgroundColor: "#ffff", backgroundSize: "cover" };
    const {content} = props;
    return (
        <div className="flex flex-col mt-10 px-3 h-full sm:h-ful md:h-full lg:h-full pt-3 md:px-10 md:pt-3" style={sectionStyle}>
            <div className="text-center">
                <h1 className="text-4xl font-extrabold sm:text-5xl md:text-6xl pt-10 text-gray-900 text-shadow-md"> {content.title} </h1>
            </div>
            <div className="flex flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row 2xl:flex-row mt-10 flex-wrap w-full justify-center">
                <div className="flex flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row 2xl:flex-row flex-wrap">
                    <div className={boxStyle} >
                        <div className="h-40"><img src={WebFormImage} width="150px" height="150px" style={{ filter: "invert(0.3)" }} alt="fill the form" /></div>
                        <div><p className="font-sans text-2xl text-gray-900 text-shadow-md font-bold mt-2">{content.first.title}</p></div>
                        <div><p className="font-sans text-base font-medium font-shadow text-gray-200 p-3 text-center">{content.first.text}</p></div>
                    </div>
                    <div className="flex flex-col mx-5 items-center justify-center transform rotate-90 md:rotate-90 lg:rotate-1">
                        <FontAwesomeIcon icon={faAngleRight} size="10x" color="#3a3e3d" />
                    </div>
                </div>
                <div className="flex flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row 2xl:flex-row flex-wrap">
                    <div className={boxStyle} >
                        <div className="h-40" style={{ filter: "invert(0.3)" }}><FontAwesomeIcon icon={faCalendarAlt} size="9x" /></div>
                        <div><p className="font-sans text-2xl text-gray-900 text-shadow-md font-bold mt-2">{content.second.title}</p></div>
                        <div><p className="font-sans text-base font-medium font-shadow text-gray-200 p-3 text-center">{content.second.text} </p></div>
                    </div>
                    <div className="flex flex-col mx-5 items-center justify-center transform rotate-90 md:rotate-90 lg:rotate-1">
                        <FontAwesomeIcon icon={faAngleRight} size="10x" color="#3a3e3d" />
                    </div>
                </div>
                <div className="flex flex-col sm:flex-col md:flex-col lg:flex-row xl:flex-row 2xl:flex-row flex-wrap">
                <div className={boxStyle}>
                    <div className="h-40" style={{ filter: "invert(0.3)" }}><FontAwesomeIcon icon={faTruck} size="9x" /></div>
                    <div><p className="font-sans text-2xl text-gray-900 text-shadow-md font-bold mt-2">{content.third.title}</p></div>
                    <div><p className="font-sans text-base font-medium font-shadow text-gray-100 p-3 text-center"> {content.third.text}</p></div>
                </div>
                </div>



            </div>
        </div>

    );
}

export default Feature;