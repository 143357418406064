export const siteContent = {
  en: {
    menu: {
      first: "Home",
      second: "About Us",
      third: "Faq",
      fourth: "Collection Request",
      fifth: "Schedule a Pickup",
    },
    home: {
      hero: {
        first: "Free up space",
        second: "in your house",
        third:
          "Our team is always ready to help you to get rid of your old books. You would only need to provide us information about how many books you have and schedule an appointment.",
        fourth: "Schedule a Pickup",
      },
      feature: {
        title: "How do we work?",
        first: {
          title: "Fill The Form",
          text: "Please feel free to contact to us to get more information. Once you decided to use our service, you can fill the 'schedule appointment form' to start the collection procedure.",
        },
        second: {
          title: "Schedule a pick up",
          text:
            "According to given information in the form we schedule a pick up. We contact to you and confirm the pick up date and time.",
        },
        third: {
          title: "Be ready for pick up",
          text: "Our pick up team will pick all the books right on time.",
        },
      },
      contactus: {
        title: "Schedule a Pickup",
        subtitle: "Fill out the form to schedule a fast, free and easy pickup.",
        field1: "NAME",
        field2: "EMAIL",
        field3: "PHONE",
        field3a: "POSTCODE",
        field3b: "Approx. Number of Books for Pickup",
        field4: "COMMENT",
        button: "Send",
        postSubmitButton: "submitted successfully",
        userNotice: "The form has successfully sent. Our team will reply to you soon.",
        buttonWarning: "Please fill email and name fields"
      },
      footer: {
        quota1: "We work in cooperation with",
      },
    },
    aboutus: {
      slogan: "New shelves for your books",
      title: "About us",
      text: "Our company was established in 2016 in London. We provide services in education and online market. We have also set up a book collection department in 2017. We collect books in the USA, Canada and whole of Europe. We bring your books to new homes and new readers. With this business model we hope to contribute to keeping the world greener and reducing global warming. Some of the collected books are donated to charities and a small portion is recycled. We sell a small quantity of the books we collect from you to generate the funds needed to continue this business model.",
      quota1: "Free up space in your house",
      quota2: "New shelves for your books",
      quota3: "A greener world",
    },
  },
  de: {
    menu: {
      first: "Haus",
      second: "Über uns",
      third: "FAQ",
      fourth: "Abholanfrage",
      fifth: "Eine Abholung einplanen",
    },
    home: {
      hero: {
        first: "Platz freigeben",
        second: "in deinem Haus",
        third:
          "Unser Team ist immer bereit, Ihnen zu helfen, Ihre alten Bücher loszuwerden. Sie müssen uns nur Informationen darüber geben, wie viele Bücher Sie haben, und einen Termin vereinbaren.",
        fourth: "Eine Abholung einplanen",
      },
      feature: {
        title: "Wie arbeiten wir?",
        first: {
          title: "Fülle das Formular aus",
          text:
            "Bitte zögern Sie nicht, uns zu kontaktieren, um weitere Informationen zu erhalten. Sobald Sie sich entschieden haben, unseren Service zu nutzen, können Sie das 'Terminvereinbarungsformular' ausfüllen, um den Abholvorgang zu starten.",
        },
        second: {
          title: "Eine Abholung einplanen",
          text:
            "Nach den Angaben im Formular planen wir eine Abholung. Wir setzen uns mit Ihnen in Verbindung und bestätigen Datum und Uhrzeit der Abholung.",
        },
        third: {
          title: "Seien Sie bereit für die Abholung",
          text: "Unser Abholteam holt alle Bücher pünktlich ab.",
        },
      },
      contactus: {
        title: "Eine Abholung einplanen",
        subtitle: "Füllen Sie das Formular aus, um eine schnelle, kostenlose und einfache Abholung zu vereinbaren.",
        field1: "NAME",
        field2: "EMAIL",
        field3: "TELEFON",
        field3a: "PLZ",
        field3b: "Ungefähre Anzahl Bücher zur Abholung",
        field4: "KOMMENTAR",
        button: "Senden",
        postSubmitButton: "erfolgreich eingereicht",
        userNotice: "Das Formular wurde erfolgreich gesendet. Unser Team wird Ihnen bald antworten.",
        buttonWarning: "Bitte füllen Sie die E-Mail- und Namensfelder aus"
      },
      footer: {
        quota1: "Wir arbeiten mit zusammen",
      },
    },
    aboutus: {
      slogan: "Neue Regale für Ihre Bücher",
      title: "Über uns",
      text: "Unser Unternehmen wurde 2016 in London gegründet. Wir bieten Dienstleistungen im Bildungs- und Online-Markt an. Wir haben 2017 auch eine Buchsammlungsabteilung eingerichtet. Wir sammeln Bücher in den USA, Kanada und ganz Europa. Wir bringen Ihre Bücher auf den neuesten Stand Häuser und neue Leser. Mit diesem Geschäftsmodell möchten wir dazu beitragen, die Welt grüner zu halten und die globale Erwärmung zu verringern. Einige der gesammelten Bücher werden für wohltätige Zwecke gespendet und ein kleiner Teil wird recycelt. Wir verkaufen eine kleine Menge der Bücher, bei denen wir sammeln Sie müssen die Mittel generieren, die zur Fortsetzung dieses Geschäftsmodells erforderlich sind.",
      quota1: "Machen Sie Platz in Ihrem Haus frei",
      quota2: "Neue Regale für Ihre Bücher",
      quota3: "Eine grünere Welt",
    }
  },
};
