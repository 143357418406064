import React from 'react';

function RadioButtonWithTwoOptionComponent(props) {
  const { imageOn, imageOff, value, hasSent, formHandler, formName, description } = props;
  const radioButtonStyle = "w-20 border-1 bg-gray-50 p-2 hover:bg-gray-200 rounded-lg cursor-pointer hover:ring-2 hover:ring-third-500";
  const radioButtonSelectedStyle = "w-20 ring-4 ring-third-500 rounded-lg";

  return (
    <>
      <span>
        <input
          disabled={hasSent === "sent"}
          className="hidden"
          type="radio"
          id={`${formName}-on`}
          value={true}
          checked={value === true ? true : false}
          name={formName}
          onChange={formHandler}
        />
        <label htmlFor={`${formName}-on`}>
          <img className={value ? radioButtonSelectedStyle : radioButtonStyle} src={imageOn} alt="" />
          <span className="text-xs font-thin" alt="">{description}</span>
        </label>
      </span>
      <span>
        <input
          disabled={hasSent === "sent"}
          className="hidden"
          type="radio"
          id={`${formName}-off`}
          value={false}
          checked={value !== true ? true : false}
          name={formName}
          onChange={formHandler}
        />
        <label htmlFor={`${formName}-off`}>
          <img className={!value ? radioButtonSelectedStyle : radioButtonStyle} src={imageOff} alt="" />
          <span className="text-xs font-thin">{`no-${description}`}</span>
        </label>
      </span>
    </>);
}

export default RadioButtonWithTwoOptionComponent;