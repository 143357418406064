import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import {
  BrowserRouter as Router
} from "react-router-dom";
import ScrollToTop from './components/scrollToTop';
import { Toaster } from 'react-hot-toast';


ReactDOM.render(
  <div className="w-full h-full">
    <Router>
      <ScrollToTop />
      <Toaster
        position="top-center"
        toastOptions={{
          duration: 2000,
        }}
      />
      <App />
    </Router>
  </div>,

  document.getElementById('root')
);
