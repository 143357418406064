import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { faAngleRight } from '@fortawesome/free-solid-svg-icons'

function Question(props) {
    const [durum, setDurum] = useState(false);

    return (

        <div className="flex flex-col w-11/12 sm:w-9/12 md:w-8/12 lg:w-7/12">
            <div className="block mx-auto w-full p-4 rounded-full bg-primary-600 shadow-lg ">
                <div className="flex flex-row justify-between px-0 md:px-2">
                    <span className="text-gray-100 text-lg lg:text-xl font-medium">{props.content.question}</span>
                    <span className={durum ? "text-grey-50 cursor-pointer transform rotate-90" : "text-grey-50 cursor-pointer"} 
                    onClick={() => setDurum(!durum)}>
                        <FontAwesomeIcon icon={faAngleRight} size="2x" color="white" rotate="90" /></span></div>
            </div>
            { durum && <div className="block p-10 font-sans font-medium text-secondary-500">
                {props.content.content}
                <props.content.formattedContent />
            </div>}
        </div>

    );
}
export default Question;