import React, { useEffect, useState } from "react";
import BackgroundImage3 from "./../assets/svg/Subtle-Prism.svg";
import messageImage from "./../assets/svg/sendmessage.png";
import ClipLoader from "react-spinners/ClipLoader";
import { motion } from "framer-motion";
import { formTransitionVariant } from "../settings.js";
import fastDelivery from "../assets/image/others/fast-delivery-fast.svg";
import fastDeliveryDisabled from "../assets/image/others/fast-delivery-disabled.svg";
import packing from "../assets/image/others/packing.svg";
import packingDisabled from "../assets/image/others/no-packing.svg";
import RadioButtonWithTwoOptionComponent from "./radioButtonWithTwoOptionComponent";
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';

function ContactForm(props) {
  const [isEmailValid, setIsEmailValid] = useState(true);
  const [isNameValid, setNameValid] = useState(true);
  const [formTouch, setFormTouch] = useState({ email: false, name: false });
  const [price, setPrice] = useState(0);
  const FAST_DELIVERY_FEE = 75;
  const PACKING_FEE = 75;

  const [form, setForm] = useState({
    name: "",
    email: "",
    phone: "",
    comment: "",
    postcode: "",
    numOfBooks: 0,
    fastDelivery: false,
    packing: false
  });

  useEffect(() => {
    const price_ = (form.fastDelivery ? FAST_DELIVERY_FEE : 0) + (form.packing ? PACKING_FEE : 0);
    setPrice(price_);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [form]);

  const { hasSent, error, content } = props;

  const history = useHistory();

  useEffect(() => {
    if (hasSent === "sent") {
      toast.success('The form has successfuly sent! We will contact to you soon.');
    }
    if (error) toast.error('An error occured! Please refresh the page and try again.');
  }, [hasSent, error]);

  const boxSize = {
    width: "30px",
    height: "30px",
  };
  const inputStyle = "w-full bg-gray-200 focus:bg-gray-300 focus:ring-third-500 focus:shadow-lg text-gray-900 mt-2 px-3 pb-1 rounded-lg focus:outline-none focus:ring-1 h-10";
  const invalidInputStyle = "w-full bg-gray-200 focus:bg-gray-300 focus:ring-red-500 ring-red-400 ring-1 focus:shadow-lg text-gray-900 mt-2 px-3 pb-1 rounded-lg focus:outline-none focus:ring-1 h-10";
  let labelStyle = "uppercase text-sm text-gray-600 font-bold";
  let formValidated = (isEmailValid && isNameValid && formTouch.email && formTouch.name);
  let submitButtonStyle = (hasSent !== "sent" && formValidated)
    ? "bg-third-500 hover:bg-third-600  focus:bg-third-700 focus:ring-third-500 text-lg font-bold"
    : "disabled bg-third-100 text-gray-500 text-lg font-medium cursor-not-allowed";
  let checkoutButtonStyle = (hasSent !== "sent" && formValidated)
    ? "text-white bg-blue-700 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-100 font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center mr-2 text-lg font-medium"
    : "disabled cursor-not-allowed text-white text-center bg-blue-200 hover:bg-blue-300 focus:ring-4 focus:outline-none focus:ring-blue-100 font-medium rounded-lg text-sm px-5 py-2.5 inline-flex items-center justify-center mr-2 text-gray-500 text-lg font-medium";


  const sectionBackgroundStyle3 = {
    backgroundImage: `url(${BackgroundImage3})`,
    backgroundColor: "#ffff",
    backgroundSize: "cover",
    height: "auto",
    paddingBottom: "20px"
  };

  let submitButtonContent = () => {
    if (hasSent === "sent") return content.postSubmitButton;
    if (hasSent === "inProgress") {
      return <ClipLoader
        color={"white"}
        css={boxSize}
        loading={true}
        size={150}
      />;
    }
    return content.button;
  }

  let proceedPaymentButtonContent = () => {
    if (hasSent === "sent") return content.postSubmitButton;
    else if (hasSent === "inProgress") {
      return <ClipLoader
        color={"white"}
        css={boxSize}
        loading={true}
        size={150}
      />;
    }
    return (<div className="flex flex-row justify-between w-full"><div>checkout</div><div>{price} £</div></div>);
  }

  const mapFormToServerRequest = (formData) => {
    return {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      comment: formData.comment,
      postcode: formData.postcode,
      numOfBooks: formData.numOfBooks,
      fastDelivery: formData.fastDelivery,
      packing: formData.packing }
  };

  const submitForm = () => {
    const formData = mapFormToServerRequest(form)
    props.onSubmit(formData);
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    let isPaymentRequired = (form.fastDelivery || form.packing) ? true : false;
    if (isPaymentRequired) {
      let payload = {form: mapFormToServerRequest(form), price: price};
      history.push({
        pathname: '/payment', state: payload
      });
      // setApprovalModalOpen(true);
      return;
    }
    submitForm();
  };

  const onChangeFormElements = (e) => {
    const { name, value } = e.target;
    let value_ = value;

    if (name === "numOfBooks") value_ = Number(value);
    if (name === "fastDelivery" || name === "packing") value_ = value === "true";


    if (name === "email") {
      setFormTouch(prev => ({ ...prev, email: true }))
      if (/^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value)) {
        setIsEmailValid(true)
      } else {
        setIsEmailValid(false)
      }
    }

    if (name === "name") {
      setFormTouch(prev => ({ ...prev, name: true }))
      if (value.length > 2) {
        setNameValid(true);
      } else {
        setNameValid(false);
      }
    }
    setForm(prev => ({ ...prev, [name]: value_ }))
  }

  return (
    <>
      <motion.div
        className="flex flex-col h-full sm:h-screen justify-center bg-gray-50 items-center px-3"
        style={sectionBackgroundStyle3}
        initial="initial"
        animate="in"
        exit="out"
        variants={formTransitionVariant}
        transition={formTransitionVariant.transition}
      >

        <div className="w-96 sm:w-9/12 md:w-8/12 lg:w-1/2 1xl:w-1/2 2xl:w-1/2 flex flex-col min-w-min items-center bg-gray-100 rounded-3xl mt-5 p-10 shadow-lg">
          <div className="w-full flex flex-row flex-wrap">
            <div className="w-1/2 flex flex-col">
              <div className="mt-2 w-full block text-left">
                <h1 className="text-2xl font-extrabold sm:text-3xl pt-5 md:text-3xl text-secondary-400 text-shadow-sm">
                  {content.title}
                </h1>
                <p className="text-md font-light">{content.subtitle}</p>
                <img
                  src={messageImage}
                  className="mt-7 block filter-grayscale transform -rotate-12"
                  alt="send message"
                />
                <div className="block text-center mb-4">
                  <a className="text-secondary-200 hover:scale-105 transform hover:text-secondary-300"
                    href="mailto:info@thecanyonbook.com">info@thecanyonbook.com</a>
                </div>
                <div className="flex flex-row justify-center space-x-5">
                  <a href="https://www.facebook.com/Free-Book-Collection-from-your-Home-106418781034251/"
                    className="text-blue-500 hover:text-blue-300 hover:scale-110 transform h-10 w-10">
                    <svg className="fill-current" viewBox="0 0 20 20">
                      <path d="M17.675,0.62H2.327c-0.942,0-1.706,0.764-1.706,1.706v15.348c0,0.941,0.764,1.705,1.706,1.705h15.348c0.941,0,1.705-0.764,1.705-1.705V2.326C19.38,1.384,18.616,0.62,17.675,0.62 M18.526,17.674c0,0.471-0.381,0.852-0.852,0.852H2.327c-0.471,0-0.853-0.381-0.853-0.852V2.326c0-0.471,0.382-0.853,0.853-0.853h15.348c0.471,0,0.852,0.382,0.852,0.853V17.674zM10.849,7.975c0-0.345,0.035-0.531,0.565-0.531h0.709V6.162h-1.134c-1.364,0-1.844,0.642-1.844,1.721v0.834h-0.85V10h0.85v3.838h1.701V10h1.134l0.151-1.283h-1.285L10.849,7.975z"></path>
                    </svg>
                  </a>
                </div>
              </div>
            </div>
            <div className="w-1/2 flex flex-col min-w-min">
              <form onSubmit={handleSubmit}>
                <div className="w-80 sm:w-full">
                  <label className={labelStyle}>{content.field1}<span className="text-red-700">*</span></label>
                  <input
                    disabled={hasSent === "sent"}
                    className={isNameValid ? inputStyle : invalidInputStyle}
                    value={form.name}
                    name="name"
                    type="text"
                    onChange={onChangeFormElements}
                  />
                  {!isNameValid && (
                    <div className="text-xs text-red-600 ml-1">
                      Please enter a valid name
                    </div>
                  )}
                </div>
                <div>
                  <label className={labelStyle}>{content.field2}<span className="text-red-700">*</span></label>
                  <input
                    disabled={hasSent === "sent"}
                    className={isEmailValid ? inputStyle : invalidInputStyle}
                    value={form.email}
                    name="email"
                    type="text"
                    onChange={onChangeFormElements}
                  />
                  {!isEmailValid && <div className="text-xs text-red-600 ml-1">
                    Please enter a valid email
                  </div>
                  }
                </div>
                <div>
                  <label className={labelStyle}>{content.field3}</label>
                  <input
                    disabled={hasSent === "sent"}
                    className={inputStyle}
                    value={form.phone}
                    name="phone"
                    type="text"
                    onChange={onChangeFormElements}
                  />
                </div>
                <div>
                  <label className={labelStyle}>{content.field3a}</label>
                  <input
                    disabled={hasSent === "sent"}
                    className={inputStyle}
                    value={form.postcode}
                    name="postcode"
                    type="text"
                    onChange={onChangeFormElements}
                  />
                </div>
                <div>
                  <label className={labelStyle}>{content.field3b}</label>
                  <input
                    disabled={hasSent === "sent"}
                    className={inputStyle}
                    value={form.numOfBooks}
                    name="numOfBooks"
                    type="number"
                    onChange={onChangeFormElements}
                  />
                </div>
                {/* <div className="flex flex-row gap-5 my-3">
                  <RadioButtonWithTwoOptionComponent
                    formHandler={onChangeFormElements}
                    imageOn={fastDelivery}
                    imageOff={fastDeliveryDisabled}
                    value={form.fastDelivery}
                    hasSent={hasSent}
                    formName="fastDelivery"
                    description="priority pickup"
                  />
                </div>
                <div className="flex flex-row gap-5 my-3">
                  <RadioButtonWithTwoOptionComponent
                    formHandler={onChangeFormElements}
                    imageOn={packing}
                    imageOff={packingDisabled}
                    value={form.packing}
                    hasSent={hasSent}
                    formName="packing"
                    description="packing"
                  />
                </div> */}
                <div>
                  <label className={labelStyle}>{content.field4}</label>
                  <textarea
                    disabled={hasSent === "sent"}
                    rows="10"
                    value={form.comment}
                    name="comment"
                    onChange={onChangeFormElements}
                    className={"w-full pt-2 sm:pt-5 bg-gray-200 focus:bg-gray-300 focus:shadow-lg focus:ring-third-500 text-gray-900 mt-2 px-3 pb-1 rounded-lg focus:outline-none focus:ring-2 h-40"}
                    type="text"
                  />
                </div>
                <div className="text-center">
                  <button
                    disabled={hasSent === "sent" || !formValidated}
                    className={`w-full  text-white shadow  translate mt-2 p-3 rounded-lg focus:outline-none focus:ring-2
                  ${price === 0 ? submitButtonStyle : checkoutButtonStyle}`}
                  >
                    {price === 0 ? submitButtonContent() : proceedPaymentButtonContent()}
                  </button>

                </div>
                {!formValidated && <div className="text-xs text-red-700 text-center">
                  {content.buttonWarning}</div>}

              </form>
            </div>
          </div>
        </div>
      </motion.div>
    </>
  );
}

export default ContactForm;
