import React from "react";
import { Link } from "react-router-dom";
import { HashLink } from "react-router-hash-link";
import logo from "./../assets/image/logo/logo5.png";
import { siteContent } from "../content/siteContent";

export class Navbar extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      activeLink: "home",
      language: "en",
    };

    this.toggleNavMenu = this.toggleNavMenu.bind(this);
    this.menuItems = this.menuItems.bind(this);
  }

  toggleNavMenu() {
    this.setState({ isOpen: !this.state.isOpen });
  }

  menuItemStyle =
    "text-secondary-600 hover:bg-primary-100 mx-2 block md:inline-block px-3 py-2 rounded-md text-base font-medium";
  menuItemStyleActive =
    "bg-primary-100 hover:bg-primary-100 text-white mx-2 block md:inline-block px-3 py-2 rounded-md text-base font-medium";
  menuItems = () => (
    <>
      <HashLink
        className={this.state.activeLink === "home"
          ? this.menuItemStyleActive
          : this.menuItemStyle}
        to={`/home?language=${this.state.language}#top`}
        onClick={() => this.setState({ activeLink: "home", isOpen: false })}
      >
        {siteContent[`${this.state.language}`].menu.first}
      </HashLink>
      <Link
        className={
          this.state.activeLink === "aboutus"
            ? this.menuItemStyleActive
            : this.menuItemStyle
        }
        to={`/aboutus?language=${this.state.language}`}
        onClick={() => this.setState({ activeLink: "aboutus", isOpen: false })}
      >
        {siteContent[`${this.state.language}`].menu.second}
      </Link>
      {/* <Link className={this.state.activeLink === "faq"? this.menuItemStyleActive : this.menuItemStyle} to='/faq' onClick= {()=> this.setState({activeLink: "faq"})} >Faq</Link> */}
      <HashLink
        smooth
        className={
          this.state.activeLink.includes("contactform")
            ? this.menuItemStyleActive
            : this.menuItemStyle
        }
        to={`/home?language=${this.state.language}#contactform`}
        onClick={() =>
          this.setState({ activeLink: `contactform`, isOpen: false })
        }
      >
        {siteContent[`${this.state.language}`].menu.fifth}
      </HashLink>
    </>
  );
  render() {
    return (
      <nav
        className="py-2 fixed z-50 bg-white border-b-2 w-full"
        style={{ marginTop: "-80px" }}
      >
        <div className="mx-auto px-2 sm:px-6 lg:px-8">
          <div className="relative w-full flex flex-row items-center justify-between h-16">
            <div>
              <Link to="/" className="inline-block">
                <img
                  className="h-14 invert"
                  src={logo}
                  alt="Canyon Book Collection"
                />
              </Link>
            </div>
            <div className="flex flex-row items-center">
              <div className="inline-block text-base font-mono">
                <Link
                  className="text-lg text-third-400 hover:text-third-600"
                  to={this.state.activeLink !== "contactform" ? `/${this.state.activeLink}?language=en` : `/home?language=${this.state.language}#contactform`}
                  onClick={() => this.setState({ language: "en" })}
                >
                  EN
                </Link>
              </div>
              <span className="m-2 text-third-600">|</span>
              <div className="inline-block text-base font-mono  pr-10">
                <Link
                  className="text-lg text-third-400 hover:text-third-600"
                  to={this.state.activeLink !== "contactform" ? `/${this.state.activeLink}?language=de` : `/home?language=de#contactform`}
                  onClick={() => this.setState({ language: "de" })}
                >
                  DE
                </Link>
              </div>
              <div className=" hidden md:block">
                <this.menuItems />
              </div>
            </div>
            <div className="inset-y-0 left-0 flex items-center md:hidden">
              <button
                onClick={this.toggleNavMenu}
                className="inline-flex items-center justify-center p-2 text-gray-400"
                aria-expanded="false"
              >
                <span className="sr-only">Open main menu</span>

                {!this.state.isOpen && (
                  <svg
                    className="block h-6 w-6 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M4 5h16a1 1 0 0 1 0 2H4a1 1 0 1 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2zm0 6h16a1 1 0 0 1 0 2H4a1 1 0 0 1 0-2z"
                    />
                  </svg>
                )}

                {this.state.isOpen && (
                  <svg
                    className="block h-6 w-6 fill-current"
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    aria-hidden="true"
                  >
                    <path
                      fillRule="evenodd"
                      d="M18.278 16.864a1 1 0 0 1-1.414 1.414l-4.829-4.828-4.828 4.828a1 1 0 0 1-1.414-1.414l4.828-4.829-4.828-4.828a1 1 0 0 1 1.414-1.414l4.829 4.828 4.828-4.828a1 1 0 1 1 1.414 1.414l-4.828 4.829 4.828 4.828z"
                    />
                  </svg>
                )}
              </button>
            </div>
          </div>
        </div>

        <div className={this.state.isOpen ? "block" : "hidden"}>
          <div className="px-2 pt-2 pb-3 space-y-1">
            <this.menuItems />
          </div>
        </div>
      </nav>
    );
  }
}
