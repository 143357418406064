export const pageTransitionVariants = {
    initial: {
        opacity: 0,
        scale: 0.8
    },
    in: {
        opacity: 1,
        x: 0,
        scale: 1

    },
    out: {
        opacity: 0,
        scale: 1.2
    },
    transition: {
        type: "tween",
        ease: "anticipate",
        duration: 0.3
    }

}
 export const formTransitionVariant = {
    initial: {
        opacity: 0,
        scale: 0.8
    },
    in: {
        opacity: 1,
        x: 0,
        scale: 1

    },
    out: {
        opacity: 0,
        scale: 1.2,
        x: "-50%"
    },
    transition: {
        type: "tween",
        ease: "anticipate",
        duration: 0.3
    }

}