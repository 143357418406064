import React from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { Navbar } from "./components/Navbar";
import { AboutUs } from "./pages/aboutus";
import { Faq } from "./pages/faq";
import { Home } from "./pages/home";
import { Payment } from "./pages/payment";
import { AnimatePresence } from "framer-motion";



function App() {
  document.title = "Canyon Book Collection";
  const location = useLocation();
  const queryParameters = new URLSearchParams(window.location.search)
  const language = queryParameters.get("language") || "en";
  return (
    <>
      <Navbar />
      <div className="mt-20 overflow-hidden">
        <AnimatePresence exitBeforeEnter>
          <Switch location={location} key={location.pathname}>
            <Route path="/aboutus"><AboutUs language={language} /></Route>
            <Route path="/faq" language={language}><Faq /></Route>
            <Route path="/payment"><Payment /></Route>
            <Route path="/"><Home language={language} /></Route>
          </Switch>
        </AnimatePresence>
      </div>
    </>
  );
}

export default App;
