import React, { useState } from "react";
import fastDelivery from "../assets/image/others/fast-delivery-fast.svg";
import { HashLink } from "react-router-hash-link";

function PriorityModal(props) {
  const [showModal, setShowModal] = useState(true);
  const handleButtonClick = (target) => {
    setShowModal(false);
  }
  return (
    <>
      {showModal && (
        <>
          <div className="w-screen h-screen opacity-5 fixed" tabIndex={0}>
          </div>
          <div
            className="flex justify-center items-center overflow-x-hidden justify-center aligh-center overflow-y-auto w-screen h-screen fixed inset-0 z-50 outline-none focus:outline-none"
            onClick={handleButtonClick}
          >
            <div className="relative w-4/5 lg:w-3/5  max-w-3xl mx-auto">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg 
              relative
              flex flex-col w-full bg-white outline-none 
              focus:outline-none bg-primary-400 border-primary-900 border-2">
                {/*header*/}
                <div className="flex items-start justify-between p-10 font-extrabold text-secondary-900">
                  <h3 className="text-2xl md:text-5xl font-extrabold text-secondary-900">
                    Priority pickup service is <span className="text-3xl md:text-7xl text-gray-200">available.</span>
                  </h3>
                  <button
                    className="ml-auto float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => setShowModal(false)}
                  >
                    <span className="text-white h-6 w-6 text-5xl block outline-none focus:outline-none" style={{ marginTop: "-40px" }}>
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="p-2 flex flex-row ">
                  <img className="w-20 md:w-32 lg:w-48 " src={fastDelivery} alt="" />
                  <p className="my-4 text-slate-500 text-xl leading-relaxed text-secondary-400 pl-10  font-extrabold">
                    Do you need an urgent pick up? Choose our priority pickup service and we schedule a pickup within three days.
                  </p>
                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 rounded-b">
                  <div className="sm:mt-5 sm:flex sm:justify-center lg:justify-start">
                    <div className="rounded-md shadow" onClick={handleButtonClick}>
                      <HashLink
                        smooth
                        to={`/home?language=en#contactform`}
                        className="w-full flex items-center justify-center 
                        px-8 py-3 text-base font-medium rounded-md 
                        text-white bg-third-500 
                        hover:bg-third-600 md:py-4 md:text-lg md:px-10">
                        schedule-pickup
                      </HashLink>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="opacity-25 fixed inset-0 tabIndex={0} z-40 bg-black" onClick={handleButtonClick}></div>
        </>
      )}
    </>
  );
}

export default PriorityModal;