import React, { useState, useEffect } from "react";
import { useLocation } from 'react-router-dom';
import { sendForm } from "../service/formService";
import toast from 'react-hot-toast';
import { useHistory } from 'react-router-dom';

export function Payment(props) {

    const location = useLocation();
    const history = useHistory();
    const [state, setState] = useState({hasSent: undefined, error: undefined }); 

    const { form, price } = location.state;
    const order = form;

    useEffect(() => {
        if (state.hasSent === "sent") {
          toast.success('The form has successfuly sent! We will contact to you soon.');
        }
        if (state.error) toast.error('An error occured! Please refresh the page and try again.'+ state.error);
      }, [state.hasSent, state.error]);

    const handleFormSubmit = (form) => {
        setState(prev => ({ ...prev, hasSent: "inProgress" }));
    
       sendForm(form)
          .then((response) => {
            setState(prev => ({ ...prev, hasSent: "sent" }))
            console.log(response);
          })
          .catch((error) => {
            console.log(JSON.stringify(error));
            setState(prev => ({ ...prev, error: error.message, hasSent: undefined }));
          })
      }
    

    const handleSubmitForm = () => {
        if(state?.hasSent === "inProgress") return;
        handleFormSubmit(form);
    }

    return (<>
        <div className="flex flex-row justify-center items-center w-full">
            <div
                className="w-full md:w-2/3 lg-1/2 xl-1/3 2xl-1/4"
            >
                <div>
                    {/*content*/}
                    <div className="rounded-lg flex flex-col bg-white border-2  bg-gray-50 mt-5">
                        {/*header*/}
                        <div className="flex items-start justify-between p-4 font-extrabold text-secondary-900">
                        </div>
     
                        <div className="pl-3 flex flex-col text-sm">
                            <div className='font-extrabold text-2xl'>Order details</div>
                            <div className='ml-4 mr-4'>
                                <span className='font-bold'>Priority service:</span><br />
                                Pick up appointment will be arranged within maximum of 3 work days.
                            </div>
                            <div className='m-4'>
                                <span className='font-bold'>Packing service:</span><br />
                                Packing service is limited to 700 books. Please contact to us for more books.
                            </div>
                            <div className='m-4'>
                                <span className='font-bold'>Payment method: </span><br />
                                You can pay for the service upon delivery using cash or credit card. Upon submission of this form, we will send the bill to your email address.
                            </div>
                            <div className='text-md mt-2 pl-4 mb-4'>
                                <span className='font-bold mb-2'>Please make sure the following information is correct. </span><br />
                                <div className='flex flex-col pl-4 mt-2'>
                                    <div className="border-b-2 w-2/3 mb-2"><span className='font-bold w-40 inline-block'>Name</span><span>{order?.name}</span></div>
                                    <div className="border-b-2 w-2/3 mb-2"><span className='font-bold w-40 inline-block'>Email</span><span>{order?.email}</span></div>
                                    <div className="border-b-2 w-2/3 mb-2"><span className='font-bold w-40 inline-block'>Phone</span><span>{order?.phone}</span></div>
                                    <div className="border-b-2 w-2/3 mb-2"><span className='font-bold w-40 inline-block'>Postcode</span><span>{order?.postcode}</span></div>
                                    <div className="border-b-2 w-2/3 mb-2"><span className='font-bold w-40 inline-block'>Number of books</span><span>{order?.numOfBooks}</span></div>
                                    <div className="border-b-2 w-2/3 mb-2">
                                        <span className='font-bold w-40 inline-block'>Priority service</span>
                                        <span className={order?.fastDelivery ? 'text-green-500' : 'text-red-500'}>
                                            {order?.fastDelivery ? "requested" : "not requested"}
                                        </span>
                                    </div>
                                    <div className="border-b-2 w-2/3 mb-2">
                                        <span className='font-bold w-40 inline-block'>Packing service</span>
                                        <span className={order?.packing ? 'text-green-500' : 'text-red-500'}>
                                            {order?.packing ? "requested" : "not requested"}
                                        </span>
                                    </div>
                                    <div className="border-b-2 w-2/3 mb-2"><span className='font-bold w-40 inline-block'>Total amount: </span><span>{price} £</span></div>

                                </div>


                            </div>
                        </div>
                        {/*footer*/}
                        {state.hasSent !== "sent" && 
                        <div className='border flex justify-end items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600'>
                            <button onClick={() => handleSubmitForm()} type="button" className='text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800'>I accept</button>
                            <button onClick={() => history.push("/home")} type="button" className='text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600'>Decline</button>
                        </div>}
                        {state.hasSent === "sent" &&
                       <div className='border flex justify-center items-center p-6 space-x-2 border-t border-gray-200 rounded-b dark:border-gray-600'>
                        <div>Thank you! Your request has sent. We will contact to you shortly.</div>
                        <button onClick={() => history.push("/home")} type="button" className='text-gray-500 bg-white hover:bg-gray-100 focus:ring-4 focus:outline-none focus:ring-gray-200 rounded-lg border border-gray-200 text-sm font-medium px-5 py-2.5 hover:text-gray-900 focus:z-10 dark:bg-gray-700 dark:text-gray-300 dark:border-gray-500 dark:hover:text-white dark:hover:bg-gray-600 dark:focus:ring-gray-600'>Back</button>
                        </div>
                        } 
                    </div>
                </div>
            </div>
        </div>
    </>)

}