import { motion } from 'framer-motion';
import React from 'react';
import Question from '../components/question';
import faqContent from '../content/faqContent';
import { pageTransitionVariants} from '../settings.js';

export class Faq extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
        };

        this.toggleNavMenu = this.toggleNavMenu.bind(this);
    }

    toggleNavMenu() {
        this.setState({ isOpen: !this.state.isOpen });
    }
    content = { question: "what is this?", content: "this is an example" };

    render() {
        return <motion.div className="flex flex-col justify-center items-start  p-3 md:p-10" style={{ minWidth: "500px" }}
        initial="initial"
        animate="in"
        exit="out"
        variants={pageTransitionVariants}
        transition = {pageTransitionVariants.transition}
        >
            <div className="w-full block">
                <h1 className="mt-10 mb-10 text-3xl tracking-tight font-bold text-secondary-500 sm:text-4xl md:text-5xl">Frequently Asked Questions </h1>
            </div>
            {faqContent.map(content => {
                return (
                <div className="flex flex-col items-center w-full mb-3 justify-center font-sans text-sm">

                    <Question content={content} />

                </div>)
            })
            }

        </motion.div>



    }
}