const requestHeader = {
    'Content-Type': 'application/json',
};


// eslint-disable-next-line consistent-return
const handleResponse = async (response) => {
    const contentType = response.headers.get('content-type');
    if (contentType) {
        if (contentType.indexOf('application/json') !== -1) {
            return response.text().then(async (text) => {
                const data = text && JSON.parse(text);
                if (!response.ok) {
                    throw new Error(text);
                }
                return data;
            });
        }
        if (!response.ok) {
            throw new Error(await response.text());
        }
        return response.text();
    }
};

function get(url) {
    const requestOptions = {
        method: 'GET',
        headers: requestHeader
    };
    return fetch(url, requestOptions)
        .then(handleResponse)
        .catch((error) => {
            throw error;
        });
}

async function post(url, body){
    const requestOptions = {
        method: 'POST',
        headers: requestHeader,
        body: JSON.stringify(body),
    };

    return fetch(url, requestOptions).then(handleResponse);
}

function put(url, body) {
    const requestOptions = {
        method: 'PUT',
        headers: requestHeader,
        body: JSON.stringify(body),
    };
    return fetch(url, requestOptions).then(handleResponse);
}


// eslint-disable-next-line import/prefer-default-export
export const sendRequest = {
    get,
    post,
    put
};
