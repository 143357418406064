import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronUp } from '@fortawesome/free-solid-svg-icons'
import { HashLink } from 'react-router-hash-link';

function Toplink(props) {
    const {language} = props;
    return (
        <HashLink smooth to={`home?language=${language}#top`}>
        <div className="fixed px-7 animate-bounce p-3 text-center rounded-lg bg-primary-300" style={{bottom: "20px", right: "20px"}}>
            <span className="animate-pulse"><p className="text-white bold text-sm">Top</p><FontAwesomeIcon icon={faChevronUp} color="white" /></span>
        </div>
        </HashLink>
    )
}

export default Toplink
